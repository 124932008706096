<template lang="pug">
.k-editor-view(:is="component")
  slot
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import Agreement from './k_editor_view/agreement.vue'

const STYLE_TYPE_MAP = {
  agreement: Agreement
} as const

const props = defineProps<{
  styleType: keyof typeof STYLE_TYPE_MAP
}>()

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})
</script>
